import React, { memo } from "react";
import {
  ZoomableGroup,
  ComposableMap,
  Geographies,
  Geography,
} from "react-simple-maps";
import { Link } from "gatsby";
import { REPORT } from "../../../../constants/routes";

const geoUrl = "./gadm36_0.json";

const MapChart = ({ setTooltipContent, countries, onClickCountry }) => {
  console.log(countries);
  return (
    <>
      <ComposableMap width={1000} height={465} data-tip="">
        <ZoomableGroup>
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map((geo) => {
                const { GID_0, NAME_0 } = geo.properties;
                if (countries.indexOf(GID_0) !== -1) {
                  return (
                    <Link
                      key={GID_0}
                      to={countries.indexOf(GID_0) !== -1 ? REPORT : null}
                      state={{ regionId: GID_0, properties: geo.properties }}
                    >
                      <Geography
                        key={geo.rsmKey}
                        geography={geo}
                        onMouseEnter={() => {
                          console.log(GID_0, NAME_0);
                          setTooltipContent(NAME_0);
                        }}
                        onMouseLeave={() => {
                          setTooltipContent("");
                        }}
                        style={{
                          default: {
                            fill:
                              countries.indexOf(GID_0) !== -1
                                ? "#1F79BC"
                                : "#D6D6DA",
                            outline: "none",
                          },
                          hover: {
                            fill: "#004689",
                            outline: "none",
                          },
                          pressed: {
                            fill: "#004689",
                            outline: "none",
                          },
                        }}
                      />
                    </Link>
                  );
                }
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    onMouseEnter={() => {
                      console.log(GID_0, NAME_0);
                      setTooltipContent(NAME_0);
                    }}
                    onMouseLeave={() => {
                      setTooltipContent("");
                    }}
                    style={{
                      default: {
                        fill:
                          countries.indexOf(GID_0) !== -1
                            ? "#1F79BC"
                            : "#D6D6DA",
                        outline: "none",
                      },
                      hover: {
                        fill: "#004689",
                        outline: "none",
                      },
                      pressed: {
                        fill: "#004689",
                        outline: "none",
                      },
                    }}
                  />
                );
              })
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
    </>
  );
};

export default memo(MapChart);
