import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectElement: {
    backgroundColor: "white",
    // border: "solid 2px #004689",
  },
}));

export default function SimpleSelect({ options, labelName, value, setValue }) {
  const classes = useStyles();

  return (
    <div>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">
          {labelName}
        </InputLabel>
        <Select
          className={classes.selectElement}
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={value}
          onChange={(event) => setValue(event.target.value)}
          label={labelName}
        >
          <MenuItem value="">
            <em>Select</em>
          </MenuItem>
          {options.map((opt) => (
            <MenuItem
              value={opt.value}
              style={
                opt.enabled
                  ? { fontWeight: "bold" }
                  : { color: "lightgrey", fontStyle: "italic" }
              }
            >
              {opt.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
