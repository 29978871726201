import React, { useState, useEffect, useMemo } from "react";
import { navigate } from "gatsby";
import moment from "moment";
import ReactPlayer from "react-player";
import PublicMap from "../PublicMap/PublicMap";
import { withFirebase } from "../../../utils/Firebase";
import LogoWithName from "../../../assets/LogoWithName.jpg";
import AppleStoreIcon from "../../../assets/AppleStoreIcon.png";
import GooglePlayIcon from "../../../assets/GooglePlayIcon.png";
import MaterialSelect from "../../atoms/MaterialSelect";
import Button from "../../atoms/Button";
import OutlinedButton from "../../atoms/OutlinedButton";
import countryProps from "../../../data/countryProps.json";
import monthNames from "../../../data/months.json";
import countryData from "../../../data/countryData.json";
import { REPORT, SUPPORT, STRUCTURE } from "../../../constants/routes";
import Divider from "../../atoms/Divider";
import {
  Person,
  AccountCircle,
  PersonAdd,
  Group,
  BlurCircular,
} from "@material-ui/icons";

// Consistent timestamp
const momentPrevHour = moment().utc();
// const momentPrevHour = moment().utc();
const momentPrevHourKey = moment(momentPrevHour)
  .utc()
  .local()
  .format("YYYY-MM-DD-HH");
const year = momentPrevHourKey.substring(0, 4);
const month = momentPrevHourKey.substring(0, 7);

const Landing = ({ firebase }) => {
  const [globalReport, setGlobalReport] = useState();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [restrictedRegions, setRestrictedRegions] = useState([]);

  useEffect(() => {
    if (firebase && !globalReport) {
      getReportObj();
    }
  }, [firebase]);

  const getReportObj = async () => {
    // create reportObj
    const reportObj = {
      [year]: {},
      [month]: {},
      overall: {},
    };

    await firebase
      .report("global")
      .get()
      .then((doc) => {
        console.log("global report RETRIEVED");
        reportObj.overall = doc.data();
      });
    await firebase
      .timeBasedReport("global", year)
      .get()
      .then((doc) => {
        console.log(`global ${year} report RETRIEVED`);
        reportObj[year] = doc.data();
      });
    await firebase
      .timeBasedReport("global", month)
      .get()
      .then((doc) => {
        console.log(`global ${month} report RETRIEVED`);
        reportObj[month] = doc.data();
      });
    const snapshot = await firebase.allRestrictedRegions().get();
    const resRegions = snapshot.docs.map((doc) => doc.id);
    setRestrictedRegions(resRegions || []);

    setGlobalReport(reportObj);
  };

  const locationsVisited = useMemo(() => {
    // create locationsObj
    const locationsObj = {
      [year]: {},
      [month]: {},
      overall: {},
    };
    if (globalReport) {
      // set overall locations
      const {
        accepted,
        delivered,
        have,
        unfinished,
        need,
        rejected,
      } = globalReport.overall;
      const oa = accepted ? +accepted : 0;
      const od = delivered ? +delivered : 0;
      const oh = have ? +have : 0;
      const ou = unfinished ? +unfinished : 0;
      const on = need ? +need : 0;
      const or = rejected ? +rejected : 0;
      locationsObj.overall = oa + od + oh + ou + on + or;

      // set yearly locations
      const ya =
        globalReport[year] && globalReport[year].accepted
          ? +globalReport[year].accepted
          : 0;
      const yd =
        globalReport[year] && globalReport[year].delivered
          ? +globalReport[year].delivered
          : 0;
      const yh =
        globalReport[year] && globalReport[year].have
          ? +globalReport[year].have
          : 0;
      const yu =
        globalReport[year] && globalReport[year].unfinished
          ? +globalReport[year].unfinished
          : 0;
      const yn =
        globalReport[year] && globalReport[year].need
          ? +globalReport[year].need
          : 0;
      const yr =
        globalReport[year] && globalReport[year].rejected
          ? +globalReport[year].rejected
          : 0;
      locationsObj[year] = ya + yd + yh + yu + yn + yr;

      // set monthly locations
      const ma =
        globalReport[month] && globalReport[month].accepted
          ? +globalReport[month].accepted
          : 0;
      const md =
        globalReport[month] && globalReport[month].delivered
          ? +globalReport[month].delivered
          : 0;
      const mh =
        globalReport[month] && globalReport[month].have
          ? +globalReport[month].have
          : 0;
      const mu =
        globalReport[month] && globalReport[month].unfinished
          ? +globalReport[month].unfinished
          : 0;
      const mn =
        globalReport[month] && globalReport[month].need
          ? +globalReport[month].need
          : 0;
      const mr =
        globalReport[month] && globalReport[month].rejected
          ? +globalReport[month].rejected
          : 0;
      locationsObj[month] = ma + md + mh + mu + mn + mr;
    }
    console.log("locationsObj", locationsObj);
    return locationsObj;
  }, [globalReport]);

  // const uploadRegionReports = () => {
  //   const reports = require("../../../utils/Migration/newRegions/reports.json");
  //   const regions = Object.keys(reports);

  //   regions.forEach((r) => {
  //     firebase
  //       .uploadReport(r, reports[r])
  //       .then(() => console.log("report uploaded: ", r))
  //       .catch((err) => {
  //         console.log("report upload ERROR: ", r);
  //       });
  //   });
  // };

  const getCountryFlag = (cc) => {
    // Mild sanity check.
    if (cc.length !== 2) return cc;

    // Convert char to Regional Indicator Symbol Letter
    const risl = (chr) => {
      return String.fromCodePoint(
        0x1f1e6 - 65 + chr.toUpperCase().charCodeAt(0)
      );
    };

    // Create RISL sequence from country code.
    return risl(cc[0]) + risl(cc[1]);
  };

  const countrySelectOptions = useMemo(() => {
    return countryProps
      .map((c) => {
        if (
          globalReport &&
          globalReport.overall &&
          globalReport.overall.activeRegions.indexOf(c.value) !== -1 &&
          restrictedRegions.indexOf(c.value) === -1
        ) {
          return {
            value: c.value,
            label: c.label,
            enabled: true,
          };
        }
        return c;
      });
  }, [globalReport]);

  return (
    <div className="landing">
      <img src={LogoWithName} height={150} className="landingLogo" />
      <p className="slogan">
        The <span className="wordOfGod">Word of God</span> is for{" "}
        <span className="everyone">EVERYONE!</span>
      </p>
      <div className="landing_app_links">
        <a
          href="https://play.google.com/store/apps/details?id=com.sharebibles.robwiebe"
          target="_blank"
        >
          <img src={GooglePlayIcon} height={75} className="appstoreLogo" />
        </a>
        <a href="https://apps.apple.com/us/app/id1141246255" target="_blank">
          <img src={AppleStoreIcon} height={75} className="appstoreLogo" />
        </a>
      </div>

      {/* <div className="ministry__divider">
        <Divider />
      </div> */}
      <div className="H_I_W_main_div">
        <h1 className="H_I_W_title">HOW IT WORKS</h1>
        <div className="types_of_users">
          <div className="landing_H_I_W_Icons">
            <Person
              style={{ color: "white", fontSize: 50, margin: "0 auto" }}
            />
            <div className="landing_H_I_W_Icons_label">Users</div>
          </div>
          <div className="landing_H_I_W_Icons">
            <AccountCircle
              style={{ color: "white", fontSize: 50, margin: "0 auto" }}
            />
            <div className="landing_H_I_W_Icons_label">Team Leaders</div>
          </div>
          <div className="landing_H_I_W_Icons">
            <PersonAdd
              style={{ color: "white", fontSize: 50, margin: "0 auto" }}
            />
            <div className="landing_H_I_W_Icons_label">Facilitators</div>
          </div>
          <div className="landing_H_I_W_Icons">
            <Group style={{ color: "white", fontSize: 50, margin: "0 auto" }} />
            <div className="landing_H_I_W_Icons_label">Teams</div>
          </div>
          <div className="landing_H_I_W_Icons">
            <BlurCircular
              style={{ color: "white", fontSize: 50, margin: "0 auto" }}
            />
            <div className="landing_H_I_W_Icons_label">Ministries</div>
          </div>
        </div>
        <div className="GoButton">
          <OutlinedButton
            text="Learn more..."
            onClick={() => navigate(STRUCTURE)}
          />
        </div>
      </div>
      <div className="">
        <h1 className="">Instructional Video</h1>
      </div>
      <div className="videoPlayer">
        <ReactPlayer
          url={"https://www.youtube.com/watch?v=BQxNWQ8aJk4"}
          // playing
          controls
        />
      </div>
      {/* <div onClick={() => uploadRegionReports()}>Upload Region Reports</div> */}
      <div className="totalCount">
        {/* <p className="subtitle">Bibles Shared:</p> */}
        <p className="count">
          <span className="countReport">
            <span className="countNumber">
              {globalReport && globalReport.overall
                ? globalReport.overall.bibles.toLocaleString()
                : "..."}
            </span>
            <span className="countTextDiv bottomMargin">
              <span className="countText">Bibles shared</span>
            </span>
            {globalReport && (
              <span className="timeBasedDiv">
                <span className="timeBasedTextDiv bottomMargin">
                  <span className="timeBasedTextHeader">
                    {monthNames[month.substring(5, 7)]}:
                  </span>
                  <span className="timeBasedText">
                    +
                    {globalReport[month] && globalReport[month].bibles
                      ? globalReport[month].bibles.toLocaleString()
                      : "0"}
                  </span>
                </span>
                <span className="timeBasedTextDiv">
                  <span className="timeBasedTextHeader">{year}:</span>
                  <span className="timeBasedText">
                    +
                    {globalReport[year] && globalReport[year].bibles
                      ? globalReport[year].bibles.toLocaleString()
                      : "0"}
                  </span>
                </span>
              </span>
            )}
          </span>
        </p>
        <div className="countDivider" />
        <p className="count">
          <span className="countReport">
            <span className="countNumber">
              {globalReport && globalReport.overall
                ? locationsVisited.overall.toLocaleString()
                : "..."}
            </span>
            <span className="countTextDiv">
              <span className="countText">Locations visited</span>
            </span>
            {globalReport && (
              <span className="timeBasedDiv">
                <span className="timeBasedTextDiv bottomMargin">
                  <span className="timeBasedTextHeader">
                    {monthNames[month.substring(5, 7)]}:
                  </span>
                  <span className="timeBasedText">
                    +{locationsVisited[month].toLocaleString() || "..."}
                  </span>
                </span>
                <span className="timeBasedTextDiv">
                  <span className="timeBasedTextHeader">{year}:</span>
                  <span className="timeBasedText">
                    +{locationsVisited[year].toLocaleString() || "..."}
                  </span>
                </span>
              </span>
            )}
          </span>
        </p>
        <div className="countDivider" />
        <p className="count">
          <span className="countReport">
            <span className="countNumber">
              {globalReport && globalReport.overall
                ? globalReport.overall.activeRegions.length
                : "..."}
            </span>
            <span className="countTextDiv">
              <span className="countText">Countries</span>
            </span>
            {globalReport && (
              <div className="countryFlags">
                {globalReport.overall.activeRegions
                  .sort((a, b) => {
                    const aName = countryData[a].NAME;
                    const bName = countryData[b].NAME;
                    return aName > bName ? 1 : -1;
                  })
                  .map((c) => {
                    if (restrictedRegions.indexOf(c) !== -1) {
                      return null;
                    }
                    return (
                      <span
                        className="singleFlag"
                        style={
                          globalReport &&
                          globalReport[month] &&
                          globalReport[month].activeRegions.indexOf(c) !== -1
                            ? {
                                // boxShadow: '0px 5px 5px white'
                                textDecoration: "underline",
                                textDecorationThickness: "2px",
                              }
                            : {}
                        }
                        onClick={() => {
                          const properties = {
                            GID_0: c,
                            NAME_0: countryData[c].NAME_LONG,
                          };
                          navigate(REPORT, {
                            state: {
                              regionId: c,
                              properties: properties,
                            },
                          });
                        }}
                      >
                        {getCountryFlag(countryData[c].ISO_A2)}
                      </span>
                    );
                  })}
              </div>
            )}
          </span>
        </p>
      </div>
      <div style={{ width: "100%" }}>
        <PublicMap
          countries={
            globalReport &&
            globalReport.overall &&
            globalReport.overall.activeRegions
              ? globalReport.overall.activeRegions.filter((c) => {
                  return restrictedRegions.indexOf(c) === -1;
                })
              : []
          }
        />
      </div>
      <div className="totalCount">
        <p className="count">
          <span className="countTextDiv">
            <span className="countText">Country Stats</span>
          </span>
        </p>
        <div className="countrySelect">
          <MaterialSelect
            labelName="Select Country"
            options={globalReport ? countrySelectOptions : []}
            value={selectedCountry}
            setValue={setSelectedCountry}
          />
          <div className="GoButton">
            <Button
              text="Go"
              disabled={
                !selectedCountry ||
                !globalReport ||
                !globalReport.overall ||
                globalReport.overall.activeRegions.indexOf(selectedCountry) ===
                  -1 ||
                restrictedRegions.indexOf(selectedCountry) !== -1
              }
              onClick={() => {
                const countryIndex = countryProps.findIndex(
                  (c) => c.value === selectedCountry
                );
                const properties = {
                  GID_0: countryProps[countryIndex].value,
                  NAME_0: countryProps[countryIndex].label,
                };
                navigate(REPORT, {
                  state: {
                    regionId: selectedCountry,
                    properties: properties,
                  },
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className="donateDiv">
        <p className="count">
          <span className="countTextDiv">
            <span className="countText">How the Share Bibles App began...</span>
          </span>
        </p>
        <div className="">
          <div className="videoPlayer">
            <ReactPlayer
              url={"https://youtu.be/p4mRMnjPtkA"}
              // playing
              controls
            />
          </div>
        </div>
      </div>
      <div className="totalCount">
        <p className="count">
          <span className="countTextDiv">
            <span className="countText">
              Donate to keep this project moving forward
            </span>
          </span>
        </p>
        <div className="GoButton">
          <OutlinedButton text="Donate" onClick={() => navigate(SUPPORT)} />
        </div>
      </div>
      <div className="footer">
        <p>
          <span>&#169; Share Bibles {new Date().getFullYear()}</span>
          <br />
          <span>
            Built by Rob Wiebe in partnership with{" "}
            <a
              className="removeLinkDecoration"
              href="https://faithtech.com/"
              target="_blank"
            >
              FaithTech
            </a>
          </span>
        </p>
      </div>
    </div>
  );
};

export default withFirebase(Landing);
